<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <div class="w-100" v-if="products !== null" style="height: inherit">

      <!-- Overlay -->
      <div class="body-content-overlay"/>

      <!-- Searchbar -->
      <div class="ecommerce-searchbar">
        <b-row>
          <b-col cols="12">
            <b-input-group class="input-group-merge">
              <b-form-input
                  v-model="searchQuery"
                  placeholder="جستجوی پست ها"
                  class="search-product"
              />
              <b-input-group-append is-text>
                <feather-icon
                    icon="SearchIcon"
                    class="text-muted"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </div>

      <!-- ECommerce Header -->
      <section id="ecommerce-header mt-1">
        <div class="row">
          <div class="col-sm-12 mt-1">
            <div class="ecommerce-header-items">
              <div class="result-toggler">
                <feather-icon
                    icon="MenuIcon"
                    class="d-block d-lg-none"
                    size="21"
                    @click="mqShallShowLeftSidebar = true"
                />
                <div class="search-results">
                  {{ totalCount }} پست یافت شد
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Prodcuts -->
      <section :class="itemView">
        <b-card
            v-for="product in products"
            :key="product.productId"
            class="ecommerce-card"
            no-body
        >
          <div class="item-img text-center pt-0">
            <b-link class="w-100 position-relative"
                    :to="{ name: 'apps-educational-post-edit', params: { id: product.productId } }">
              <b-img
                  :alt="`${product.name}-${product.productId}`"
                  fluid
                  class="card-img-top"
                  :src="'https://api.maxterms.com/'+product.imageFile"
              />
              <b-badge
                  v-if="product.fileData !== ''"
                  class="product-badge p-1"
                  variant="light-primary"
              >
                دارای مدیا
              </b-badge>
            </b-link>
          </div>

          <!-- Product Details -->
          <b-card-body>
            <div class="item-wrapper">
              <h6 class="item-name">
                <b-link
                    class="text-body"
                    :to="{ name: 'apps-educational-post-edit', params: { id: product.productId } }"
                >
                  {{ product.title }}
                </b-link>
              </h6>
              <div>
                <h6 v-if="!product.isFree" class="item-price text-info">
                  {{ makePrettyPrice(product.price) }} تومان
                </h6>
              </div>
            </div>
            <b-card-text class="item-description">
              {{ product.description }}
            </b-card-text>
            <b-badge  variant="info">
              <feather-icon
                  icon="EyeIcon"
                  class="mr-25"
              />
              <span> {{ product.viewCounts }}</span>
            </b-badge>
          </b-card-body>

          <!-- Product Actions -->
          <div class="item-options text-center">
            <div v-if="!product.isFree" class="item-wrapper">
              <div class="item-cost">
                <h4 class="item-price">
                  {{ makePrettyPrice(product.price) }} تومان
                </h4>
              </div>
            </div>
            <b-button
                variant="danger"
                tag="a"
                class="btn-wishlist"
                v-b-modal.modal-delete
                @click="showModal(product.productId)"
            >
              <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                  :class="{'text-danger': product.isInWishlist}"
              />
              <span>حذف</span>
            </b-button>
            <b-button
                variant="light"
                tag="a"
                class="btn-cart"
                @click.prevent="$router.push({ name: 'apps-educational-post-edit', params: { id: product.productId } })"
            >
              <feather-icon
                  icon="ShoppingCartIcon"
                  class="mr-50"
              />
              <span>ویرایش</span>
            </b-button>
            <b-button
                variant="info"
                tag="a"
                class="btn-cart"
                @click.prevent="$router.push({ name: 'apps-e-commerce-add-product-media', params: { id: product.productId } , query : {productType : product.type} })"
            >
              <feather-icon
                  icon="CastIcon"
                  class="mr-50"
              />
              <span>مدیا</span>
            </b-button>
          </div>
        </b-card>
      </section>

      <!-- Pagination -->
      <section>
        <b-row>
          <b-col cols="12">
            <b-pagination
                v-model="currentPage"
                :total-rows="totalCount"
                per-page="9"
                first-number
                align="center"
                last-number
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon style="transform: rotate(180deg)"
                              icon="ChevronLeftIcon"
                              size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>
    </div>
    <b-modal
        id="modal-delete"
        centered
        ok-title="حذف"
        cancelTitle="انصراف"
        @ok="deleteProduct(deleteItem)"
    >
      <span>حذف شود؟</span>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
  BOverlay, BBadge, BModal
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {watch} from '@vue/composition-api'
import {useResponsiveAppLeftSidebarVisibility} from '@core/comp-functions/ui/app'
import ShopLeftFilterSidebar from './ECommerceShopLeftFilterSidebar.vue'
import {useShopFiltersSortingAndPagination, useShopUi, useShopRemoteData} from './useECommerceShop'
import {useEcommerceUi} from '../useEcommerce'
import {ProductGetAllRequest} from "@/libs/Api/Product";
import {ProductDeleteProductRequest} from "@/libs/Api/Product";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {ProductCategoryGetAllRequest} from "@/libs/Api/Product";
import Helper from "@/libs/Helper";
import {ProductCategoryGetAllProductCategoriesRequest} from "@/libs/Api/Product";

export default {
  title: 'لیست پست های آموزشی - پنل ادمین مکس ',
  data() {
    return {
      products: null,
      totalCount: null,
      productCategories: null,
      deleteItem: null,
      currentPage: 1,
      showOverlay: false,
      searchQuery: '',
      categoryId: 0,
      type: 5,
      types: [
        {label: 'دوره', value: 1},
        // {label : 'فیلم' , value : 2},
        {label: 'فایل', value: 3},
        {label: 'پادکست', value: 4},
      ],
      defaultGetProductData: {
        categoryId: 0,
        searchByTitle: '',
        pageNumber: 1,
        count: 9,
        type: 5
      }
    }
  },
  async created() {
    await this.getProducts(this.defaultGetProductData.pageNumber, this.defaultGetProductData.searchByTitle, this.defaultGetProductData.categoryId, this.defaultGetProductData.type);
  },
  methods: {
    async getProducts(pageNumber, search, categoryId, type) {
      let _this = this;
      _this.showOverlay = true;
      let getProductData = {
        categoryId: categoryId,
        searchByTitle: search,
        pageNumber: pageNumber,
        count: 9,
        type: type,
        isFree: ''
      }

      let productGetAllRequest = new ProductGetAllRequest(_this);
      productGetAllRequest.setParams(getProductData)
      await productGetAllRequest.fetch(function (content) {
        _this.products = content.products;
        _this.totalCount = content.productsCount;
        _this.showOverlay = false;
      }, function (content) {
        console.log(content);
        _this.showOverlay = false;
      });
    },
    async deleteProduct(param) {
      let _this = this;
      _this.showOverlay = true;

      let productDeleteProductRequest = new ProductDeleteProductRequest(_this);
      productDeleteProductRequest.setId(param)
      await productDeleteProductRequest.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `حذف شد.`,
          },
        })
        _this.showOverlay = false;
        _this.getProducts(_this.defaultGetProductData.pageNumber, _this.defaultGetProductData.searchByTitle, _this.defaultGetProductData.categoryId, _this.defaultGetProductData.type);
      }, function (error) {
        _this.showOverlay = false;
        console.log(error);
      });
    },
    changeCategory(param) {
      this.categoryId = param;
    },
    changeType(param) {
      this.type = param;
    },
    makePrettyPrice(param) {
      return Helper.prettyPrice(param, 0)
    },
    showModal(param) {
      let _this = this;
      _this.deleteItem = param
    },
  },
  watch: {
    currentPage: function (nv, ov) {
      this.getProducts(nv, this.defaultGetProductData.searchByTitle, this.defaultGetProductData.categoryId, this.defaultGetProductData.type);
    },
    searchQuery: function (nv, ov) {
      this.getProducts(this.defaultGetProductData.pageNumber, nv, this.defaultGetProductData.categoryId, this.defaultGetProductData.type);
    },
    categoryId: function (nv, ov) {
      this.getProducts(this.defaultGetProductData.pageNumber, this.defaultGetProductData.searchByTitle, nv, this.defaultGetProductData.type);
    },
    type: function (nv, ov) {
      this.getProducts(this.defaultGetProductData.pageNumber, this.defaultGetProductData.searchByTitle, this.defaultGetProductData.categoryId, nv);
    },
  },
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    BOverlay,
    BBadge,
    BModal,

    // SFC
    ShopLeftFilterSidebar,
  },
  setup() {
    const {
      filters, filterOptions, sortBy, sortByOptions,
    } = useShopFiltersSortingAndPagination()

    const {handleCartActionClick, toggleProductInWishlist} = useEcommerceUi()

    const {
      itemView, itemViewOptions, totalProducts,
    } = useShopUi()

    const {products, fetchProducts} = useShopRemoteData()

    const {mqShallShowLeftSidebar} = useResponsiveAppLeftSidebarVisibility()

    // Wrapper Function for `fetchProducts` which can be triggered initially and upon changes of filters
    const fetchShopProducts = () => {
      fetchProducts({
        q: filters.value.q,
        sortBy: sortBy.value.value,
        page: filters.value.page,
        perPage: filters.value.perPage,
      })
          .then(response => {
            products.value = response.data.products
            totalProducts.value = response.data.total
          })
    }

    fetchShopProducts()

    watch([filters, sortBy], () => {
      fetchShopProducts()
    }, {
      deep: true,
    })

    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,
      toggleProductInWishlist,
      handleCartActionClick,

      // useShopRemoteData
      products,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

img {
  height: 25rem !important;
}
</style>
